import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'

import { ThemeProvider } from '@krowdy/kds-styles'
import { SnackbarProvider } from 'notistack'
import { createTheme, CssBaseline, krowdyTheme } from '@krowdy/kds-core'

class Root extends Component {
  render() {
    const { store, children } = this.props

    return (
      <Provider store={store}>
        <ThemeProvider theme={createTheme(krowdyTheme)}>
          <CssBaseline />
          <SnackbarProvider
            anchorOrigin={{
              horizontal: 'center',
              vertical  : 'top'
            }}
            autoHideDuration={2000}
            maxSnack={3}>
            {children}
          </SnackbarProvider>
        </ThemeProvider>
      </Provider>
    )
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired
}

export default Root
